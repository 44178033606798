import React from 'react';
import './style/App.scss';
import { Switch, Route } from "react-router-dom";

// Components
import DesktopNav from './components/navbar/desktop-nav';
import MobileNav from './components/navbar/mobile-nav';
import Backdrop from "./components/navbar/backdrop";
import Hero from './components/hero/hero';
import Portfolio from "./components/portfolio/portfolio";
import Partners from "./components/partners/partners";
import About from "./components/about/about";
import Blog from "./components/blog/blog";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";
import Tnc from './Tnc';
import PrivacyPolicy from './PrivacyPolicy';
import Disclaimer from './Disclaimer';
import { locations } from './locations';
 
class App extends React.Component {
  state = {
    userIsScrolled: false,
    mobileNavbarOpen: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.userIsScrolled);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.userIsScrolled);
  }

  // Detect if user is scorlled down (used for add/disable extra large navbar)
  userIsScrolled = () => {
    if (window.pageYOffset > 80) {
      this.setState({ userIsScrolled: true });
    } else {
      this.setState({ userIsScrolled: false });
    }
  };
  // On closeMobileMenu click close navbar
  closeMobileMenu = () => {
    this.setState({ mobileNavbarOpen: false });
  };
  // Mobile menu handler
  mobileMenuOpen = () => {
    this.setState({ mobileNavbarOpen: true });
  };

  render() {
    // BACKDROP RENDER
    let backdrop = <Backdrop closeMobileMenu={this.closeMobileMenu} />;
    if (this.state.mobileNavbarOpen) {
      backdrop = (
        <Backdrop closeMobileMenu={this.closeMobileMenu} isOpen={true} />
      );
    }
    // MOBILE NAVBAR RENDER
    let mobileNavbar = <MobileNav />;
    if (this.state.mobileNavbarOpen) {
      mobileNavbar = (
        <MobileNav isOpen={true} closeMobileMenu={this.closeMobileMenu} />
      );
    }

    return (
      <>
        {
          window.location.pathname === "/defaultsite" ?
             window.location.replace("https://www.vcsscanada.com")
          : null
        }
        <Switch>
          <Route exact path="/">
              <div className="App">
                {mobileNavbar}
                {backdrop}
                <DesktopNav
                  userIsScrolled={this.state.userIsScrolled}
                  mobileMenuOpen={this.mobileMenuOpen}
                />
                <Hero />
                <Blog />
                <About />
                <Contact />
                <Footer />
              </div>
          </Route>
          {locations.map((location) => (
            <Route
              key={location}
              path={`/${location.toLowerCase()}`}
              render={(props) => (
                <div className="App">
                {mobileNavbar}
                {backdrop}
                <DesktopNav
                  userIsScrolled={this.state.userIsScrolled}
                  mobileMenuOpen={this.mobileMenuOpen}
                />
                <Hero {...props} location={location} />
                <Blog />
                <About />
                <Contact />
                <Footer />
                </div>
              )}
            />
          ))}
          <Route path="/terms-and-conditions">
            <Tnc/>
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy/>
          </Route>
          <Route path="/disclaimer">
            <Disclaimer/>
          </Route>
          <Route path="*">
            <div className="App">
              {mobileNavbar}
              {backdrop}
              <DesktopNav
                userIsScrolled={this.state.userIsScrolled}
                mobileMenuOpen={this.mobileMenuOpen}
              />
                <Hero />
                <Blog />
                <About />
                <Contact />
                <Footer />
            </div>
          </Route>
        </Switch>
      </>
    );
  }
}

export default App;
