import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import "./about.scss";
import { locations } from "../../locations"; // Adjust the import path based on your folder structure
// Components
import Title from "../ui-components/title/title";
// Assets
import About from "../../assets/about/about.jpg";

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="About Us" />
      <p className="font30 weight800">
        V Connect Security Services - VCSS
      </p>
      <Row style={{ marginTop: '50px' }}>
        <Col md={12} lg={6}>
          <img src={About} alt="About" style={{ borderRadius: '15px' }} />
        </Col>
        <Col md={12} lg={6}>
          <p className='para'>
            <br />
            At V Connect Security Services (VCSS), we are a leading security guard services company dedicated to providing top-notch protection and peace of mind. With our team of highly trained professionals, cutting-edge technology, and customized solutions, we strive to exceed our clients' expectations by delivering comprehensive security services for construction sites, events, executives, residential and commercial properties, as well as offering risk assessment, alarm response, and facility management. Our unwavering commitment to excellence and our focus on ensuring safety and security make us the trusted choice for all your security needs.
          </p>
        </Col>
      </Row>
      <p className="font30 weight800" style={{ marginTop: '50px' }}>
        We also provide our services in the following areas
      </p>
      <Row style={{ marginTop: '30px' }}>
        {locations.map(location => (
          <Col xs={6} md={4} lg={3} key={location}>
            <a href={`/${location.toLowerCase()}`} className="location-link">
              {location}
            </a>
          </Col>
        ))}
      </Row>
    </div>
  </div>
);

export default about;
