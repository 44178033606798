// locations.js
export const locations = [
    "ACTON", "AJAX", "ALLISTON", "AMHERSTBURG", "ANCASTER", "ANGUS", "ARNPRIOR",
    "ARTHUR", "AURORA", "AYLMER", "AYR", "BARRIE", "BEAMSVILLE", "BEAVERTON",
    "BELLEVILLE", "BLENHEIM", "BOBCAYGEON", "BOLTON", "BOWMANVILLE", "BRACEBRIDGE",
    "BRADFORD", "BRAMPTON", "BRANTFORD", "BRIDGENORTH", "BRIGHTON", "BROCKVILLE",
    "BROOKLIN", "BRUSSELS", "BURFORD", "BURLINGTON", "CALEDON EAST", "CALEDONIA",
    "CAMBRIDGE", "CAPREOL", "CARLETON PLACE", "CAYUGA", "CHATHAM", "CHESLEY",
    "CLINTON", "COBOCONK", "COBOURG", "COCHRANE", "COLBORNE", "COLLINGWOOD",
    "COMBER", "CONCORD", "CORNWALL", "COURTICE", "DELHI", "DESERONTO", "DRESDEN",
    "DRYDEN", "DUNDALK", "DUNDAS", "DUNNVILLE", "DURHAM", "EAST GWILLIMBURY",
    "EAST YORK", "ELLIOT LAKE", "EMBRUN", "EMO", "ENGLEHART", "ESSEX", "ETOBICOKE",
    "EXETER", "FENELON FALLS", "FERGUS", "FLESHERTON", "FONTHILL", "FOREST",
    "FORT ERIE", "FORT FRANCES", "GANANOQUE", "GEORGETOWN", "GLENCOE", "GLOUCESTER",
    "GODERICH", "GRIMSBY", "GUELPH", "HAGERSVILLE", "HALIBURTON", "HAMILTON",
    "HANOVER", "HARRISTON", "HARROW", "HAWKESBURY", "HEARST", "HOLLAND LANDING",
    "HUNTSVILLE", "ILDERTON", "INGERSOLL", "KANATA", "KAPUSKASING", "KEMPTVILLE",
    "KENORA", "KESWICK", "KILLALOE STATION", "KINCARDINE", "KING CITY", "KINGSTON",
    "KINGSVILLE", "KIRKLAND LAKE", "KITCHENER", "OAKVILLE", "OMEMEE", "ORANGEVILLE",
    "ORILLIA", "ORLEANS", "OSHAWA", "OTTAWA", "OWEN SOUND", "PALMERSTON", "PARIS",
    "PARKHILL", "PARRY SOUND", "PEFFERLAW", "PEMBROKE", "PERTH", "PETAWAWA",
    "PETERBOROUGH", "PETROLIA", "PICKERING", "PICTON", "PORT COLBORNE", "PORT ELGIN",
    "PORT HOPE", "PORT PERRY", "PORT STANLEY", "RED LAKE", "RENFREW", "RICHMOND",
    "RICHMOND HILL", "RIDGETOWN", "RIDGEWAY", "ROCKLAND", "ROSEMONT", "SARNIA",
    "SAULT STE MARIE", "SCARBOROUGH", "SCHOMBERG", "SEAFORTH", "SELKIRK", "SIMCOE",
    "SIOUX LOOKOUT", "SMITHS FALLS", "SMITHVILLE", "SOUTHAMPTON", "ST CLEMENTS",
    "ST. CATHARINES", "ST. THOMAS", "STITTSVILLE", "STONEY CREEK", "STOUFFVILLE",
    "STRATFORD", "STRATHROY", "STURGEON FALLS", "SUDBURY", "SUNDERLAND", "TAMWORTH",
    "TAVISTOCK", "TECUMSEH", "TERRANCE BAY", "THORNHILL", "THUNDER BAY", "TILBURY",
    "TILLSONBURG", "TIMMINS", "TIVERTON", "TORONTO", "TOTTENHAM", "TRENTON", "TWEED",
    "UXBRIDGE", "VAL CARON", "VAUGHAN", "VINELAND", "WALKERTON", "WALLACEBURG",
    "WASAGA BEACH", "WATERDOWN", "WATERLOO", "WELLAND", "WELLESLEY", "WHITBY",
    "WINDSOR", "WINGHAM", "WOODBRIDGE", "WOODSTOCK"
];